import client, { CollectionResponse } from 'shared/services/client'

export type MerchantAccount = {
  type: string
  id: string
  displayName: string
  createdAt: string
  updatedAt: string
  outboundWebhookUrl: string | null
  outboundWebhookUsername: string | null
  outboundWebhookPassword: string | null
  visaNetworkTokensRequestorId?: string
  visaNetworkTokensAppId?: string
  mastercardNetworkTokensRequestorId?: string
  mastercardNetworkTokensAppId?: string
  amexNetworkTokensRequestorId?: string
  amexNetworkTokensAppId?: string
  loonClientKey: string | null
  loonSecretKey: string | null
  loonAcceptedSchemes: string[] | null
}

export const listMerchantAccounts = async (
  params: { cursor?: string; limit?: number; search?: string } = {}
): Promise<CollectionResponse<MerchantAccount>> => {
  const response = await client.get('/merchant-accounts', { params })
  return response.data
}

export const addMerchantAccount = async (
  merchantAccount: Pick<MerchantAccount, 'id' | 'displayName'>
): Promise<MerchantAccount> => {
  const response = await client.post('/merchant-accounts', merchantAccount)
  return response.data
}

export const getMerchantAccount = async (
  id: string
): Promise<MerchantAccount> => {
  const response = await client.get(`/merchant-accounts/${id}`)
  return response.data
}

export type UpdateMerchantAccountName = Pick<
  MerchantAccount,
  'id' | 'displayName'
>

export const updateMerchantAccountName = async ({
  id,
  displayName,
}: UpdateMerchantAccountName): Promise<MerchantAccount> => {
  const response = await client.put(`/merchant-accounts/${id}`, { displayName })
  return response.data
}

export type UpdateMerchantAccountWebhook = Pick<
  MerchantAccount,
  | 'id'
  | 'displayName'
  | 'outboundWebhookUrl'
  | 'outboundWebhookUsername'
  | 'outboundWebhookPassword'
>

export const updateMerchantAccountWebhook = async ({
  id,
  ...payload
}: UpdateMerchantAccountWebhook): Promise<MerchantAccount> => {
  const response = await client.put(`/merchant-accounts/${id}`, payload)
  return response.data
}

export type UpdateMerchantAccountNetworkTokens = Omit<
  MerchantAccount,
  'type' | 'createdAt' | 'updatedAt'
>

export const updateMerchantAccountNetworkTokens = async ({
  id,
  ...payload
}: UpdateMerchantAccountNetworkTokens): Promise<MerchantAccount> => {
  const response = await client.put(`/merchant-accounts/${id}`, payload)
  return response.data
}

export type UpdateMerchantAccount = Omit<
  MerchantAccount,
  'type' | 'createdAt' | 'updatedAt'
>

export const updateMerchantAccount = async ({
  id,
  ...payload
}: UpdateMerchantAccount): Promise<MerchantAccount> => {
  const response = await client.put(`/merchant-accounts/${id}`, payload)
  return response.data
}
