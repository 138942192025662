import { Alert, Checkbox, Stack, Text, TextLink } from '@gr4vy/poutine-react'
import { Form } from 'antd'
import { useParams } from 'react-router-dom'
import { EditPaymentServiceForm } from 'connections/components/EditPaymentServiceForm/EditPaymentServiceForm'
import { usePaymentServiceAndDefinition } from 'connections/hooks/use-payment-service-and-definition'
import { FormItem } from 'shared/components/FormItem'
import { Loading } from 'shared/components/Loading'
import { pathTo } from 'shared/paths/reports'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'

export const PaymentServiceSettlementReporting = () => {
  const { merchantAccountId } = useParams<{
    merchantAccountId: string
  }>()
  const form = Form.useFormInstance()
  const settlementReportingEnabled = form.getFieldValue([
    'settlementReportingEnabled',
  ])
  const { paymentService, isLoading } = usePaymentServiceAndDefinition()
  const hasPermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {paymentService?.settlementReportingEnabled && merchantAccountId && (
        <Alert variant="information">
          <Alert.Icon />
          <Alert.Content>
            <Alert.Title>Settlement reporting schedule</Alert.Title>
            <Alert.Text>
              Next, to include the data received from the payment service in
              Gr4vy’s consolidated settlement reports, you need to enable the
              settlement report schedule in{' '}
              <TextLink
                href={pathTo.allReports(merchantAccountId, 'financial')}
              >
                Reports
              </TextLink>
              .
            </Alert.Text>
          </Alert.Content>
        </Alert>
      )}
      <Stack>
        <Text as="span">
          Enable this connection to ingest settlement details from the payment
          service.
        </Text>
        <FormItem
          name={['settlementReportingEnabled']}
          valuePropName="checked"
          trigger="onCheckedChange"
          shouldUpdate
          help={
            !settlementReportingEnabled && (
              <Text as="span" paddingTop={4} fontSize="xs">
                When disabled the settlement data will not be ingested from the
                payment service.
              </Text>
            )
          }
        >
          <Checkbox disabled={!hasPermission}>Enable settlement</Checkbox>
        </FormItem>
      </Stack>
    </>
  )
}

export const PaymentServiceSettlementReportingPage = () => (
  <EditPaymentServiceForm submitButtonText="Save changes">
    <PaymentServiceSettlementReporting />
  </EditPaymentServiceForm>
)
