import { Description } from '@gr4vy/poutine-react'
import { Status } from 'shared/components/Status'
import { getStatus } from 'transactions/helpers/get-status'
import { TransactionSummary } from 'transactions/services'

export interface TransactionStatusProps {
  transaction: TransactionSummary
  skipGiftCards?: boolean
  skipReviewText?: boolean
}

export const TransactionStatus = ({
  transaction,
  skipGiftCards,
  skipReviewText,
}: TransactionStatusProps) => {
  const [statusLabel, statusLabelAddon] = getStatus(
    transaction,
    skipGiftCards,
    skipReviewText
  )

  const rawResponseAddon =
    ['authorization_declined', 'authorization_failed'].includes(
      transaction.status
    ) && transaction.rawResponseDescription
  const showSubText = statusLabelAddon || rawResponseAddon

  return (
    <Description>
      <Description.Icon size={24} src={<Status value={transaction.status} />} />
      <Description.Text>{statusLabel}</Description.Text>
      {!!showSubText && (
        <Description.SubText>{showSubText}</Description.SubText>
      )}
    </Description>
  )
}
