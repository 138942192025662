import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { backgroundColor } from 'shared/constants/merchant'
import { AccessLevel, Resource, RestrictAccess } from 'shared/permissions'
import styles from './TopNavLink.module.scss'

export type TopNavLinkProps = {
  path: string
  title: string
  resource?: Resource
}

export const TopNavLink = ({ path, title, resource }: TopNavLinkProps) => {
  const link = (
    <NavLink
      className={({ isActive }) =>
        clsx(styles.titleBarLink, {
          [styles.active]: isActive,
        })
      }
      style={({ isActive }) => (isActive ? { backgroundColor } : undefined)}
      to={path}
    >
      {title}
    </NavLink>
  )

  return resource ? (
    <RestrictAccess resource={resource} accessLevel={AccessLevel.read}>
      {link}
    </RestrictAccess>
  ) : (
    link
  )
}
