import { Grid, GridItem } from '@gr4vy/poutine-react'
import { Input, Select } from 'antd'
import { RuleConfigOutcome } from 'flows/constants'
import { FormItem } from 'shared/components/FormItem'

interface OutcomeBooleanProps {
  outcomeConfig: RuleConfigOutcome
}

export const OutcomeBoolean = ({ outcomeConfig }: OutcomeBooleanProps) => (
  <Grid>
    <GridItem gridColumn="span 3">
      <FormItem name={['outcome', 'type']} hidden>
        <Input />
      </FormItem>
      <FormItem name={['outcome', 'result']}>
        <Select>
          <Select.Option value={true}>
            {outcomeConfig.values?.true}
          </Select.Option>
          <Select.Option value={false}>
            {outcomeConfig.values?.false}
          </Select.Option>
        </Select>
      </FormItem>
    </GridItem>
  </Grid>
)
