import { getTransactionStatusConfig } from 'transactions/constants/transactionStatuses'
import { TransactionSummary } from 'transactions/services/transactions'

const getMethod = (
  transaction: Pick<TransactionSummary, 'paymentMethod' | 'giftCardRedemptions'>
) => {
  if (transaction.paymentMethod) {
    return transaction.paymentMethod?.method == 'card' ? 'card' : 'redirect'
  }

  if (!!transaction?.giftCardRedemptions?.length) {
    return 'gift-card'
  }

  return null
}

export const getStatus = (
  transaction: Pick<
    TransactionSummary,
    | 'status'
    | 'errorCode'
    | 'paymentMethod'
    | 'amount'
    | 'authorizedAmount'
    | 'refundedAmount'
    | 'capturedAmount'
    | 'pendingReview'
    | 'giftCardRedemptions'
    | 'settled'
  >,
  skipGiftCards?: boolean,
  skipReviewText?: boolean
): [string, string | null] => {
  const method = getMethod(transaction)
  const transactionStatus = transaction.settled ? 'settled' : transaction.status
  const key = [method, transactionStatus, transaction.errorCode || 'no_error']
    .join('.')
    .toLowerCase()

  const label = getTransactionStatusConfig(transactionStatus).label

  const subLabel =
    transaction.pendingReview && !skipReviewText ? 'In review' : null

  const hasFullRefundedAllGiftCards = !!transaction.giftCardRedemptions.every(
    (giftCardRedemption) =>
      giftCardRedemption.status != 'succeeded' ||
      (giftCardRedemption.refundedAmount > 0 &&
        giftCardRedemption.amount == giftCardRedemption.refundedAmount)
  )

  const hasSomeGiftCardRefund = !!transaction.giftCardRedemptions.some(
    (giftCardRedemption) => giftCardRedemption.refundedAmount > 0
  )

  switch (key) {
    // 3DS pending
    case 'card.buyer_approval_pending.no_error':
      return [label, '3DS']

    // 3DS incomplete
    case 'card.authorization_failed.incomplete_buyer_approval':
      return [label, '3DS incomplete']

    // Approval incomplete
    case 'redirect.authorization_failed.incomplete_buyer_approval':
      return [label, 'Approval incomplete']

    // Approval Failed
    case 'card.authorization_declined.failed_buyer_approval':
      return [label, '3DS failed']

    // Missing redirect_url
    case 'card.authorization_failed.missing_redirect_url':
      return [label, 'Missing redirect_url']

    // Flow Decline
    case 'card.authorization_declined.flow_decline':
    case 'redirect.authorization_declined.flow_decline':
    case 'gift-card.authorization_declined.flow_decline':
      return [label, 'Declined by a Flow rule']

    case 'card.capture_succeeded.no_error':
    case 'redirect.capture_succeeded.no_error':
    case 'card.settled.no_error':
      // Refunds
      if (transaction.refundedAmount > 0) {
        const isTransactionFullRefunded =
          transaction.refundedAmount == transaction.amount ||
          transaction.refundedAmount == transaction.capturedAmount

        if (skipGiftCards) {
          return isTransactionFullRefunded
            ? [label, 'Full refund']
            : [label, 'Partial refund']
        } else {
          return isTransactionFullRefunded && hasFullRefundedAllGiftCards
            ? [label, 'Full refund']
            : [label, 'Partial refund']
        }
      } else if (hasSomeGiftCardRefund && !skipGiftCards) {
        return [label, 'Partial refund']
      }
      // Partial/Over captures
      if (
        transaction.capturedAmount > 0 &&
        transaction.capturedAmount != transaction.authorizedAmount
      ) {
        return transaction.capturedAmount > transaction.authorizedAmount
          ? [label, 'Over capture']
          : [label, 'Partial capture']
      } else {
        return [label, subLabel]
      }

    // Default
    default:
      // Gift Card Refunds
      if (
        key.startsWith('gift-card') &&
        !!transaction.giftCardRedemptions.length
      ) {
        if (hasSomeGiftCardRefund) {
          return hasFullRefundedAllGiftCards
            ? [label, 'Full refund']
            : [label, 'Partial refund']
        }
      }
  }

  return [label, subLabel]
}
