import { Action, Flow } from 'shared/constants'
import { AmountCondition, Operation } from './flow'
import { OutcomeResult } from './outcome'

export type RuleOutcomeConfig = {
  selectedLabel: string
  selectedLabelV2?: string
  selectedPlaceholder: string
  selectedPlaceholderV2?: string
  unselectedLabel: string
  unselectedPlaceholder: string
  emptyErrorMessage: string
  hideControls?: boolean
}

export enum RuleOutcomeType {
  BOOLEAN = 'boolean',
  LIST = 'list',
  CARD_ROUTING = 'card-routing',
  THREE_D_SECURE = 'three-d-secure',
}

export type RuleCondition = {
  name: AmountCondition | string
  operator?: Operation
  value?: any
}

export type RuleOutcomeCardRouting = {
  type: RuleOutcomeType.CARD_ROUTING
  result: string[]
  retryDeclines: number
  version?: number
}

export type RuleOutcomeCardRoutingV2 = {
  type: RuleOutcomeType.CARD_ROUTING
  result: OutcomeResult[]
  retryDeclines: number
  version?: number
}

export type RuleOutcome3DS = {
  type: RuleOutcomeType.THREE_D_SECURE
  result: 'attempt' | 'force' | 'skip'
}

export type RuleOutcome =
  | {
      type: RuleOutcomeType.LIST
      result: string[]
    }
  | {
      type: RuleOutcomeType.BOOLEAN
      result: boolean
    }
  | RuleOutcomeCardRouting
  | RuleOutcomeCardRoutingV2
  | RuleOutcome3DS

export interface Rule {
  id: string
  description: string
  conditions: RuleCondition[]
  outcome: RuleOutcome
  position: number
  flow: Flow
  action: Action
}

export const ruleOutcomeConfig = {
  [Flow.checkout]: {
    [Action.selectPaymentOption]: {
      selectedLabel: 'VISIBLE',
      selectedPlaceholder:
        'Drag a hidden payment option to this area to show it in the checkout',
      unselectedLabel: 'HIDDEN',
      unselectedPlaceholder:
        'Drag a visible payment option to this area to hide it in the checkout',
      emptyErrorMessage: 'One payment option must be visible at all times',
    },
  },
  [Flow.cardTransactions]: {
    [Action.routeTransaction]: {
      selectedLabel: 'SELECTED',
      selectedLabelV2: 'IN USE',
      selectedPlaceholder:
        'Drag a connection to this area to use it in this rule',
      selectedPlaceholderV2: 'Please add at least one connection.',
      unselectedLabel: 'NOT SELECTED',
      unselectedPlaceholder:
        'Drag a connection to this area to not use it in this rule',
      emptyErrorMessage: 'One payment option must be visible at all times',
    },
  },
  [Flow.redirectTransactions]: {
    [Action.routeTransaction]: {
      selectedLabel: 'IN USE',
      selectedPlaceholder:
        'Drag a connection to this area to use it in this rule',
      unselectedLabel: 'NOT USED',
      unselectedPlaceholder:
        'Drag a connection to this area to not use it in this rule',
      hideControls: true,
      emptyErrorMessage: 'One connection must be used to trigger this rule',
    },
  },
}
