import { Box, Container, Stack } from '@gr4vy/poutine-react'
import { useQuery } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import { useParams } from 'react-router-dom'
import GiftCardPageLayout from 'gift-cards/components/GiftCardPageLayout/GiftCardPageLayout'
import { TRANSACTIONS } from 'shared/constants/query-keys/transactions'
import { is } from 'shared/helpers/is'
import { useCursor, useFilters } from 'shared/hooks'
import { TransactionFilters as ITransactionFilters } from 'shared/services/transactions'
import TransactionFilters from 'transactions/components/TransactionFilters'
import TransactionTable, {
  transactionColumns,
} from 'transactions/components/TransactionTable'
import { TransactionTableColumnId } from 'transactions/constants/table'
import { listTransactions, TransactionSummary } from 'transactions/services'

const GiftCardTransactionsPage = () => {
  const { giftCardId } = useParams<{ giftCardId: string }>()
  const [filters, setFilters] = useFilters<ITransactionFilters>()
  const [cursor] = useCursor()

  const page = useQuery({
    queryKey: [TRANSACTIONS, cursor, filters, giftCardId],
    queryFn: () =>
      listTransactions({
        cursor,
        ...filters,
        giftCardId,
      }),
    enabled: !is.emptyObject(filters) && !!giftCardId,
  })

  const updateFilter = (f: ITransactionFilters) => {
    setFilters({ ...filters, ...f })
  }

  const formatColumns = (column: ColumnDef<TransactionSummary>) =>
    ({
      [column.id as string]: column,
      [TransactionTableColumnId.PAYMENT_METHOD]: {
        ...column,
        header: 'Payment Method',
      },
    })[column.id as string]

  const paymentMethodColumns = transactionColumns.map(formatColumns)

  return (
    <GiftCardPageLayout>
      <Box padding={32}>
        <Container>
          <Stack gap={32}>
            <TransactionFilters filters={filters} onFilter={updateFilter} />
            <TransactionTable
              page={page}
              columns={paymentMethodColumns}
              pagination={{ filters, onFilter: updateFilter }}
            />
          </Stack>
        </Container>
      </Box>
    </GiftCardPageLayout>
  )
}

export default GiftCardTransactionsPage
