import {
  Description,
  Flex,
  FlexProps,
  Icon,
  Stack,
  Timeline,
  Tooltip,
} from '@gr4vy/poutine-react'
import currencyFormat from 'shared/helpers/currency-format'
import { getTooltipTransactionHistory } from 'transactions/helpers/get-tooltip-transaction-history'
import { Transaction, TransactionSummary } from 'transactions/services'
import styles from './TransactionAmount.module.scss'

export interface TransactionAmountProps extends FlexProps {
  transaction: TransactionSummary
}

export const TransactionAmount = ({
  transaction,
  ...rest
}: TransactionAmountProps) => {
  const amount = currencyFormat(transaction.amount, {
    currency: transaction?.currency,
    style: 'decimal',
  })
  const isPartialRefund =
    transaction.refundedAmount < transaction.authorizedAmount &&
    transaction.refundedAmount > 0

  const isPartialCapture =
    transaction.capturedAmount < transaction.authorizedAmount &&
    transaction.capturedAmount > 0

  let transactionHistory
  if (isPartialRefund || isPartialCapture) {
    transactionHistory = getTooltipTransactionHistory(
      transaction as Transaction
    )
  }

  return (
    <Flex justifyContent="flex-end" gap={4} {...rest}>
      {(isPartialRefund || isPartialCapture) && (
        <Tooltip
          mode="light"
          content={
            <Stack className={styles.timeLineWrapper}>
              <Timeline>
                <>
                  {transactionHistory?.map((transaction, index) => (
                    <Timeline.Item
                      variant={
                        transaction.status === 'Succeeded' ? 'success' : 'black'
                      }
                      key={index}
                    >
                      {`${transaction.amount} ${transaction.event}`}
                    </Timeline.Item>
                  ))}
                </>
              </Timeline>
            </Stack>
          }
        >
          <Icon name="info" size="small" className={styles.infoIcon} />
        </Tooltip>
      )}
      <Description align="flex-end">
        <Description.Text
          style={{
            fontVariantNumeric: 'tabular-nums',
          }}
        >
          {amount}
        </Description.Text>
        <Description.SubText>{transaction.currency}</Description.SubText>
      </Description>
    </Flex>
  )
}
