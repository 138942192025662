import { Condition, Operation } from './flow'

export enum ConfigOutcomeType {
  BOOLEAN = 'boolean',
  LIST = 'list',
  CARD_ROUTING = 'card-routing',
  THREE_D_SECURE = 'three-d-secure',
}

export type RuleConfigCondition = {
  label: string
  name: Condition
  operator: Operation
  unique: boolean
  hidden?: boolean
}

type BooleanValues = {
  true: string
  false: string
}

export type RuleConfigOutcome = {
  type: ConfigOutcomeType
  max?: number
  values?: BooleanValues
}

export type RuleConfig = {
  conditions: RuleConfigCondition[]
  outcome: RuleConfigOutcome
}
