import { Stack } from '@gr4vy/poutine-react'
import { Form } from 'antd'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Outcome3DS } from 'flows/components/Outcome3DS/Outcome3DS'
import { OutcomeBoolean } from 'flows/components/OutcomeBoolean/OutcomeBoolean'
import { OutcomeCardRouting } from 'flows/components/OutcomeCardRouting/OutcomeCardRouting'
import { OutcomeList } from 'flows/components/OutcomeList/OutcomeList'
import {
  ConfigOutcomeType,
  ActionOutcome,
  Outcome,
  RuleConfigOutcome,
  outcomeSectionConfig,
  OutcomeSectionConfig,
} from 'flows/constants'
import { FlowContext } from 'flows/contexts/FlowContext'
import { componentConfigSelector } from 'flows/utils/componentConfigSelector'
import { CardOutcomeV2 } from './CardOutcomeV2'
import { Section } from './Section'

export const OutcomeSection = ({
  outcomes,
  outcomeConfig,
  outcomeCardVersion,
}: {
  outcomes: Outcome[]
  outcomeConfig: RuleConfigOutcome
  outcomeCardVersion?: number
}) => {
  const { id } = useParams()
  const form = Form.useFormInstance()
  const { flow, action } = useContext(FlowContext)
  const config = componentConfigSelector<OutcomeSectionConfig>(
    flow,
    action,
    outcomeSectionConfig
  )

  return (
    <Stack gap={16}>
      {outcomeConfig.type === ConfigOutcomeType.CARD_ROUTING &&
      (outcomeCardVersion === 2 || !id) ? (
        <CardOutcomeV2
          form={form}
          outcomes={outcomes}
          config={config}
          outcomeConfig={outcomeConfig}
        />
      ) : (
        <>
          <Stack gap={64}>
            {outcomeConfig.type === ConfigOutcomeType.CARD_ROUTING && (
              <OutcomeCardRouting />
            )}
            <Section title={config?.title}>{config?.text}</Section>
          </Stack>
          {[ConfigOutcomeType.LIST, ConfigOutcomeType.CARD_ROUTING].includes(
            outcomeConfig.type
          ) && (
            <OutcomeList
              form={form}
              outcomes={outcomes as ActionOutcome[]}
              outcomeConfig={outcomeConfig}
            />
          )}
          {outcomeConfig.type === ConfigOutcomeType.THREE_D_SECURE && (
            <Outcome3DS />
          )}
          {outcomeConfig.type === ConfigOutcomeType.BOOLEAN && (
            <OutcomeBoolean outcomeConfig={outcomeConfig} />
          )}
        </>
      )}
    </Stack>
  )
}
