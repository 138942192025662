export const ADMIN_PATH = '/admin'
export const MERCHANTS_PATH = '/merchants/:merchantAccountId'
export const EDIT_MERCHANT_PATH = `${ADMIN_PATH}/merchants/:merchantAccountId`
export const EDIT_MERCHANT_OVERVIEW_PATH = `${ADMIN_PATH}/merchants/:merchantAccountId/overview`
export const EDIT_MERCHANT_NETWORK_TOKENS_PATH = `${ADMIN_PATH}/merchants/:merchantAccountId/network-tokens`
export const EDIT_MERCHANT_VAULT_FORWARDING_PATH = `${ADMIN_PATH}/merchants/:merchantAccountId/vault-forwarding`
export const EDIT_MERCHANT_VAULT_FORWARDING_AUTHENTICATION_PATH = `${ADMIN_PATH}/merchants/:merchantAccountId/vault-forwarding/:id`
export const EDIT_WEBHOOK_PATH = `${ADMIN_PATH}/merchants/:merchantAccountId/webhooks`
export const EDIT_ACCOUNT_UPDATER_PATH = `${ADMIN_PATH}/merchants/:merchantAccountId/account-updater`

export const pathTo = {
  admin: ADMIN_PATH,
  merchants: `${ADMIN_PATH}/merchants`,
  users: `${ADMIN_PATH}/users`,
  apiKeys: `${ADMIN_PATH}/integrations`,
  apiLogs: `${ADMIN_PATH}/integrations/logs`,
  merchant: (id: string) => `${ADMIN_PATH}/merchants/${id}/overview`,
  merchantWebhook: (id: string) => `${ADMIN_PATH}/merchants/${id}/webhooks`,
  merchantNetworkTokens: (id: string) =>
    `${ADMIN_PATH}/merchants/${id}/network-tokens`,
  merchantVaultForwarding: (id: string) =>
    `${ADMIN_PATH}/merchants/${id}/vault-forwarding`,
  accountUpdater: (id: string) =>
    `${ADMIN_PATH}/merchants/${id}/account-updater`,
  user: (id: string) => `${ADMIN_PATH}/users/${id}`,
  apiKey: (id: string) => `${ADMIN_PATH}/api-keys/${id}`,
  apiLog: (id: string) => `${ADMIN_PATH}/api-logs/${id}`,
  vaultForwarding: (merchantAccountId: string) =>
    `${ADMIN_PATH}/merchants/${merchantAccountId}/vault-forwarding`,
  vaultForwardingAuthentication: (merchantAccountId: string, id: string) =>
    `${ADMIN_PATH}/merchants/${merchantAccountId}/vault-forwarding/${id}`,
}
