import { Box, Description, Flex } from '@gr4vy/poutine-react'
import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { usePaymentMethodDefinition } from 'shared/hooks'
import { pathTo as pathToGiftCards } from 'shared/paths/gift-cards'
import { pathTo as pathToPaymentMethods } from 'shared/paths/payment-methods'
import { PaymentMethodsTag } from 'transactions/components/PaymentMethodsTag'
import { giftCardIconUrl } from 'transactions/constants/giftCards'
import { useTransactionPaymentMethod } from 'transactions/hooks/use-transaction-payment-methods'
import { PaymentMethod, Transaction } from 'transactions/services'
import { GiftCardMaskedNumber } from '../MaskedCardNumber/GiftCardMaskedNumber'

interface TransactionOverviewOtherPaymentProps {
  transaction: Transaction
  paymentMethod?: PaymentMethod
}

const TransactionOverviewOtherPayment = ({
  transaction,
  paymentMethod,
}: TransactionOverviewOtherPaymentProps) => {
  const { merchantAccountId } = useParams() as { merchantAccountId: string }
  const { cardNumber } = useTransactionPaymentMethod({
    paymentMethod: transaction?.paymentMethod,
    giftCardRedemptions: transaction?.giftCardRedemptions,
  })
  const paymentMethodDefinition = usePaymentMethodDefinition(
    transaction?.paymentMethod?.method
  )
  const giftCardMaskedNumber: ReactNode = (
    <GiftCardMaskedNumber cardNumber={cardNumber} />
  )

  return (
    <Flex
      alignItems={
        !!(
          transaction.paymentMethod?.label &&
          paymentMethodDefinition?.displayName
        )
          ? 'flex-start'
          : 'center'
      }
    >
      {paymentMethodDefinition && (
        <Box minWidth={0}>
          <Description>
            <Description.Icon src={paymentMethodDefinition.iconUrl} />
            {paymentMethod?.id && transaction?.buyer?.id ? (
              <Description.Link
                href={pathToPaymentMethods.paymentMethodId(
                  merchantAccountId,
                  paymentMethod?.id
                )}
              >
                {transaction.paymentMethod?.label ||
                  paymentMethodDefinition.displayName}
              </Description.Link>
            ) : (
              <Description.Text>
                {transaction.paymentMethod?.label
                  ? transaction.paymentMethod?.label
                  : paymentMethodDefinition.displayName}
              </Description.Text>
            )}
            {!!(
              transaction.paymentMethod?.label &&
              paymentMethodDefinition.displayName
            ) && (
              <Description.SubText>
                {paymentMethodDefinition.displayName}
              </Description.SubText>
            )}
          </Description>
        </Box>
      )}
      {!paymentMethodDefinition && !!transaction.giftCardRedemptions.length && (
        <Box minWidth={0}>
          <Description>
            <Description.Icon src={giftCardIconUrl} />
            {transaction.giftCardRedemptions[0].giftCard.id ? (
              <Description.Link
                href={pathToGiftCards.giftCardId(
                  merchantAccountId,
                  transaction.giftCardRedemptions[0].giftCard.id
                )}
              >
                {giftCardMaskedNumber as string}
              </Description.Link>
            ) : (
              <Description.Text>
                {giftCardMaskedNumber as string}
              </Description.Text>
            )}
          </Description>
        </Box>
      )}
      <PaymentMethodsTag
        paymentMethod={transaction?.paymentMethod}
        giftCardRedemptions={transaction?.giftCardRedemptions}
      />
    </Flex>
  )
}

export default TransactionOverviewOtherPayment
